<template>
  <b-card>
    <!-- Modal -->
    <b-modal
      modal-class="oderDetail"
      v-model="dialog"
      size="lg"
      :title="modalTitle"
      ok-title="Close"
      header-close-content=""
      ok-only
    >
      <b-container>
        <div class="text-center" v-if="isLoading">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
        <div v-else class="d-flex flex-column justify-content-between w-100 oderDetailBody">
          <div class="oderDetailContent">
            <b-card class="section-body">
              <div class="ticketItemContent">
                <div class="accordion">
                  <b-card no-body class="mb-lg-1 mb-3 myToggle">
                    <b-card-header header-tag="header" class="" role="tab">
                      <b-button
                        v-b-toggle
                        :href="`#order_${viewItems.display_ticket_id}`"
                        @click.prevent
                        class="myToggleBtn"
                      >
                        <p class="font-15">Order</p>
                        <p class="btnChooseMoney"></p>
                      </b-button>
                    </b-card-header>
                    <b-collapse
                      :id="'order_' + viewItems.display_ticket_id"
                      accordion="my-accordion"
                      role="tabpanel"
                      class="myToggleContent"
                    >
                      <b-card-body>
                        <div class="transaction-info">
                          <div class="transaction-info-row d-flex">
                            <div class="transaction-info-item f-1">
                              <div class="item-title">User Name</div>
                              <div class="item-value">
                                {{ viewItems.order.account.username }}
                              </div>
                            </div>
                            <div class="transaction-info-item f-1">
                              <div class="item-title">Status</div>
                              <div class="item-value">
                                {{ viewItems.status === 1 ? 'Success' : 'Fail' }}
                              </div>
                            </div>
                            <div class="transaction-info-item f-1">
                              <div class="item-title">Receiver Name</div>
                              <div class="item-value">
                                {{ viewItems.order.receiver_name }}
                              </div>
                            </div>
                          </div>
                          <div class="transaction-info-row d-flex">
                            <div class="transaction-info-item f-1">
                              <div class="item-title">Receiver Phone</div>
                              <div class="item-value">
                                {{ viewItems.order.receiver_phone }}
                              </div>
                            </div>
                            <div class="transaction-info-item f-1">
                              <div class="item-title">Shipping Method</div>
                              <div class="item-value">
                                {{ viewItems.order.shipping_method.name }}
                              </div>
                            </div>
                            <div class="transaction-info-item f-1">
                              <div class="item-title">Payment Method</div>
                              <div class="item-value">
                                {{ viewItems.order.payment_method.name }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
                <div class="accordion">
                  <b-card no-body class="mb-lg-1 mb-3 myToggle">
                    <b-card-header header-tag="header" class="" role="tab">
                      <b-button
                        v-b-toggle
                        :href="`#detail_${viewItems.display_ticket_id}`"
                        @click.prevent
                        class="myToggleBtn"
                      >
                        <p class="font-15">Order Detail</p>
                        <p class="btnChooseMoney"></p>
                      </b-button>
                    </b-card-header>
                    <b-collapse
                      :id="'detail_' + viewItems.display_ticket_id"
                      accordion="my-accordion"
                      role="tabpanel"
                      class="myToggleContent"
                    >
                      <b-card-body>
                        <div
                          class="receiver-info d-flex"
                          v-for="(order, index) in viewItems.order.order_details"
                          :key="index"
                        >
                          <div class="ticket-item w-100">
                            <div class="ticket-detail">
                              <div class="ticket-name">
                                {{ order.ticket_name }}
                              </div>
                              <div class="product-name pr-3">
                                {{ order.period_name }}
                              </div>
                              <div class="product-name">
                                {{ order.game.name }}
                              </div>
                              <span class="ticket-price float-right correct"
                                >{{ formatPrice(order.price) }}&nbsp;<img style="position: relative; top: -2px"
                              /></span>
                              <span class="ticket-price float-right correct"
                                >{{ order.product.name + ' | ' }}&nbsp;<img style="position: relative; top: -2px"
                              /></span>
                            </div>
                            <div
                              class="ticket-result d-flex flex-wrap"
                              v-if="!ticketStatus.includes(order.ticket_info)"
                            >
                              <div
                                :class="{
                                  ticketItemListNumber: true
                                }"
                                v-for="(number, index) in JSON.parse(order.ticket_info)"
                                :key="index"
                              >
                                {{ number }}
                              </div>
                            </div>
                            <div class="ticket-result d-flex flex-wrap" v-else>
                              <div>
                                {{ order.ticket_info }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
                <div class="accordion">
                  <b-card no-body class="mb-lg-1 mb-3 myToggle">
                    <b-card-header header-tag="header" class="" role="tab">
                      <b-button
                        v-b-toggle
                        :href="`#operation_${viewItems.display_ticket_id}`"
                        @click.prevent
                        class="myToggleBtn"
                      >
                        <p class="font-15">Operation Logs</p>
                        <p class="btnChooseMoney"></p>
                      </b-button>
                    </b-card-header>
                    <b-collapse
                      :id="'operation_' + viewItems.display_ticket_id"
                      accordion="my-accordion"
                      role="tabpanel"
                      class="myToggleContent"
                    >
                      <b-card-body>
                        <div class="kt-timeline-v2">
                          <div class="kt-timeline-v2__items kt-padding-top-25 kt-padding-bottom-30">
                            <perfect-scrollbar style="max-height: 100vh; position: relative">
                              <template v-for="(item, i) in timeline">
                                <div class="kt-timeline-v2__item" :key="`item-${i}`">
                                  <span class="kt-timeline-v2__item-time">{{ item.time }}</span>
                                  <div class="kt-timeline-v2__item-cricle">
                                    <i v-bind:class="item.icon"></i>
                                  </div>
                                  <div
                                    class="kt-timeline-v2__item-text kt-padding-top-5"
                                    v-bind:class="{
                                      'kt-timeline-v2__item-text--bold': item.bold
                                    }"
                                    v-html="item.text"
                                  ></div>
                                  <div
                                    :key="`images-${i}`"
                                    v-if="item.images"
                                    class="kt-list-pics kt-list-pics--sm kt-padding-l-20"
                                  >
                                    <template v-for="(item, i) in item.images">
                                      <a href="#" :key="i" v-html="item"></a>
                                    </template>
                                  </div>
                                </div>
                              </template>
                            </perfect-scrollbar>
                          </div>
                        </div>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
                <div v-if="viewItems.ticket_transfer_log" class="accordion">
                  <b-card no-body class="mb-lg-1 mb-3 myToggle">
                    <b-card-header header-tag="header" class="" role="tab">
                      <b-button
                        v-b-toggle
                        :href="`#transfer_log_${viewItems.display_ticket_id}`"
                        @click.prevent
                        class="myToggleBtn"
                      >
                        <p class="font-15">Transfer Logs</p>
                        <p class="btnChooseMoney"></p>
                      </b-button>
                    </b-card-header>
                    <b-collapse
                      :id="'transfer_log_' + viewItems.display_ticket_id"
                      accordion="my-accordion"
                      role="tabpanel"
                      class="myToggleContent"
                    >
                      <b-card-body>
                        <div class="transaction-info">
                          <div class="transaction-info-item">
                            <div class="item-title">Customer's name</div>
                            <div class="item-value">
                              {{ viewItems.ticket_transfer_log.customer_name }}
                            </div>
                          </div>
                          <div class="transaction-info-item">
                            <div class="item-title">CMND/CCCP</div>
                            <div class="item-value">
                              {{ viewItems.ticket_transfer_log.customer_id }}
                            </div>
                          </div>
                          <div class="transaction-info-item">
                            <div class="item-title">Customer's phonenumber</div>
                            <div class="item-value">
                              {{ viewItems.ticket_transfer_log.customer_phone }}
                            </div>
                          </div>
                          <div class="transaction-info-item mt-3">
                            <img
                              :src="viewItems.ticket_transfer_log.image_url"
                              class="w-100"
                              @click="openGallery('o_transfer_detail')"
                            />
                            <LightBox
                              :ref="'lightbox_o_transfer_detail'"
                              :media="[{ src: viewItems.ticket_transfer_log.image_url }]"
                              :show-light-box="false"
                              :show-thumbs="false"
                            ></LightBox>
                          </div>
                        </div>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </div>
            </b-card>
          </div>
          <div v-if="editedItem.transfer_status == 0 && !editedItem.is_paid" class="row ml-auto">
            <button @click="handleManualReward" v-if="editedItem.reward_type == 2" class="btn btn-success mx-3">
              Reward Manual
            </button>
            <button @click="requestPayoutTicket" v-if="editedItem.reward_type == 2" class="btn btn-success">
              Reward
            </button>
            <button @click="dialogUploadTransfer = true" class="mx-3 btn btn-danger">Transfer</button>
          </div>
        </div>
      </b-container>
    </b-modal>
    <b-modal
      modal-class="oderDetail"
      v-model="dialogUploadTransfer"
      size="lg"
      title="Ticket transfer'"
      ok-title="Lưu"
      header-close-content=""
      cancel-title="Huỷ"
      @ok="uploadTransferInfo"
      @cancel="closeUploadDialog"
    >
      <b-container>
        <div class="d-flex flex-column justify-content-between w-100 oderDetailBody">
          <b-row>
            <b-col cols="6">
              <b-form-group id="input-name">
                <label for="name">Customer's fullname:<span class="text-danger">*</span></label>
                <b-form-input
                  id="name"
                  v-model="uploadCustomerName"
                  type="text"
                  name="name"
                  v-validate="{ required: true, min: 3, regex: /^\S[^`~!@#$%^&*()_+={}\[\]|\\:;“’<,>.?๐฿0-9]*$/ }"
                  placeholder="Customer's fullname"
                  data-vv-as="Customer's fullname"
                ></b-form-input>
                <span v-show="errors.has('name')" class="is-danger-text position-relative">{{
                  errors.first('name')
                }}</span>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group id="input-id">
                <label for="customer_id">Customer's Id: <span class="text-danger">*</span></label>
                <b-form-input
                  trim
                  id="customer_id"
                  name="customer_id"
                  v-model="uploadCustomerID"
                  v-validate="{ min: 9, max: 12, required: true }"
                  type="number"
                  data-vv-as="CMND/CCCD"
                  placeholder="CMND/CCCD"
                ></b-form-input>
                <span v-show="errors.has('customer_id')" class="is-danger-text position-relative">{{
                  errors.first('customer_id')
                }}</span>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group id="input-phone">
                <label for="phoneNumber">Customer's phonenumber: <span class="text-danger">*</span></label>
                <b-form-input
                  trim
                  type="number"
                  id="phoneNumber"
                  name="phoneNumber"
                  v-validate="{ min: 10, max: 10, regex: /(03|07|08|09|05)+([0-9]{8})\b/, required: true }"
                  v-model="uploadCustomerPhone"
                  data-vv-as="Customer's phonenumber"
                  placeholder="Customer's phonenumber"
                ></b-form-input>
                <span v-show="errors.has('phoneNumber')" class="is-danger-text position-relative">
                  {{ errors.first('phoneNumber') }}
                </span>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group id="input-img-file" label="Image File:" label-for="img-file">
                <b-form-file
                  v-model="uploadImage"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                ></b-form-file>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </b-modal>
    <!-- Header -->
    <b-row class="table-filter">
      <b-col cols="12">
        <h4 class="b-card-title">Big Prize Ticket List</h4>
      </b-col>

      <!-- Filter game  -->
      <b-col cols="2">
        <b-form-group id="input-screen-filter" label="Game" label-for="screen-filter">
          <b-form-select id="screen-filter" v-model="filter_game" :options="allGameAdsList" @change="fetchData">
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col cols="2">
        <b-form-group id="input-screen-filter" label="Game" label-for="screen-filter">
          <b-form-select
            id="screen-filter"
            v-model="filter_reward_status"
            :options="rewardStatusFilter"
            @change="fetchData"
          >
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>

      <!-- Filter period -->
      <b-col>
        <b-form-group id="input-name-filter" label="Period Name" label-for="name-filter">
          <b-form-input
            id="name-filter"
            v-model="filter_period"
            :disabled="isFilterByGame"
            type="text"
            placeholder="Search period name..."
            @input="onFilterName"
          ></b-form-input>
        </b-form-group>
      </b-col>

      <!-- filter date from -->
      <b-col>
        <b-form-group id="input-from-date" label="From Date:" label-for="from-date">
          <datePicker
            v-model="filter_from"
            :config="dpOptions"
            :disabled="isFilterByGame"
            @dp-change="fetchData"
          ></datePicker>
        </b-form-group>
      </b-col>

      <!-- filter date to -->
      <b-col>
        <b-form-group id="input-from-date" label="From Date:" label-for="from-date">
          <datePicker
            v-model="filter_to"
            :config="dpOptions"
            :disabled="isFilterByGame"
            @dp-change="fetchData"
          ></datePicker>
        </b-form-group>
      </b-col>
    </b-row>
    <!-- Table -->
    <b-table
      striped
      :fields="headers"
      :items="items"
      :busy="isBusy"
      :current-page="currentPage"
      responsive
      bordered
      class="elevation-1 data-table text-center"
    >
      <template v-slot:cell(price)="item">
        {{ formatPrice(item.item.price) }}
      </template>
      <template v-slot:cell(lottery_time)="item">
        {{ item.item.period.lottery_time }}
      </template>
      <template v-slot:cell(period_name)="item">
        {{ item.item.period.name }}
      </template>
      <template v-slot:cell(game)="item">
        {{ item.item.game.name }}
      </template>
      <template v-slot:cell(image_preview)="item">
        <img :src="item.value[0].src" style="height: 50px" @click="openGallery(item.item.id)" />
        <LightBox
          :ref="'lightbox_' + item.item.id"
          :media="item.value"
          :show-light-box="false"
          :show-thumbs="false"
        ></LightBox>
      </template>
      <template v-slot:cell(origin_image_preview)="item">
        <img :src="item.value[0].src" style="height: 50px" @click="openGallery('o' + item.item.id)" />
        <LightBox
          :ref="'lightbox_o' + item.item.id"
          :media="item.value"
          :show-light-box="false"
          :show-thumbs="false"
        ></LightBox>
      </template>
      <template v-slot:cell(reward_amount)="item">
        {{ formatPrice(item.item.reward_amount) }}
      </template>
      <template v-slot:cell(transfer_status)="item">
        <div v-if="item.item.transfer_status == 0">
          <b-badge class="mr-1" v-if="item.item.is_paid" variant="success">Paid</b-badge>
          <b-badge class="mr-1" v-else variant="warning">Pending</b-badge>
        </div>
        <div v-else>
          <b-badge class="mr-1" variant="success">Transfered</b-badge>
        </div>
      </template>

      <template v-slot:cell(actions)="item">
        <v-icon class="text-success mr-3" @click="viewItem(item.item)">mdi-eye</v-icon>
      </template>
    </b-table>
    <!-- Footer -->
    <b-row>
      <b-col cols="5">
        Showing {{ (currentPage - 1) * perPage + 1 }}-{{
          currentPage * perPage > rows ? rows : currentPage * perPage
        }}
        of {{ rows }}.
      </b-col>
      <b-col style="display: flex; justify-content: flex-end">
        <b-form inline style="margin-right: 20px; margin-bottom: 1rem">
          <label class="mr-sm-2" for="per-page">Rows per page: </label>
          <b-form-select
            id="per-page"
            v-model="perPage"
            size="sm"
            :options="[5, 10, 15, 20]"
            @change="fetchData"
          ></b-form-select>
        </b-form>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          align="right"
          v-on:input="fetchData"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import datePicker from 'vue-bootstrap-datetimepicker';
import { formatDateForView } from '@/utils/time.js';
import LightBox from 'vue-image-lightbox';
import ApiService from '@/common/api.service.js';
import _ from 'lodash';
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
  name: 'Tickets',
  components: { datePicker, LightBox },
  data() {
    return {
      dialogUploadTransfer: false,
      uploadCustomerName: '',
      uploadCustomerPhone: '',
      uploadCustomerID: '',
      uploadImage: null,
      nameValidate: null,
      invalidNameMessage: '',
      idValidate: null,
      invalidIdMessage: '',
      invalidPhoneMessage: '',
      phoneValidate: null,
      isLoading: true,
      isFilterByGame: true,
      search: '',
      isBusy: false,
      filter_period: '',
      filter_game: null,
      filter_from: '',
      filter_to: '',
      headers: [
        { label: 'ID', key: 'id' },
        { label: 'Game', key: 'game' },
        { label: 'Period', key: 'period_name' },
        // { label: "Barcode", key: "barcode" },
        { label: 'Image', key: 'image_preview' },
        { label: 'Origin Image', key: 'origin_image_preview' },
        { label: 'Printed Time', key: 'printed_at' },
        // { label: "dispatched_at", key: "dispatched_at" },
        // { label: "full_serial", key: "full_serial" },
        { label: 'Last Four Serial', key: 'last_four_serial' },
        { label: 'Note', key: 'notice' },
        { label: 'Reward amount', key: 'reward_amount' },
        // { label: "lottery_time", key: "lottery_time" },
        { label: 'Status', key: 'transfer_status', class: 'text-center' },
        { label: 'Actions', key: 'actions', class: 'text-center' }
      ],
      // Modal data
      items: [],
      selectedGame: null,
      modalTitle: '',
      // Modal validate
      rows: 0,
      perPage: 10,
      currentPage: 1,
      editedItem: {
        id: '',
        barcode: '',
        created_at: '',
        dispatched_at: '',
        display_ticket_id: '',
        full_serial: '',
        status: '',
        image_url: '',
        last_four_serial: '',
        notice: '',
        printed_at: '',
        lottery_time: '',
        name: '',
        period_id: '',
        ticket_name: '',
        period_name: '',
        price: '',
        game: {
          name: ''
        },
        product: {
          name: ''
        }
      },
      dialog: false,
      editedIndex: -1,
      dpOptions: {
        format: 'MM/DD/YYYY',
        sideBySide: true
      },
      viewItems: [],
      allGameAdsList: [],
      rewardStatusFilter: ['Pending', 'Paid', 'Transfer'],
      rewardStatusFilterStatus: { Pending: 0, Paid: 1, Transfer: 2 },
      filter_reward_status: 'Pending',
      timeline: [
        {
          time: '16:00',
          icon: 'fa fa-genderless kt-font-brand',
          text: 'Lorem ipsum dolor sit amit,consectetur eiusmdd tempor<br>incididunt ut labore et dolore magna elit enim at minim<br>veniam quis nostrud'
        },
        {
          time: '17:00',
          icon: 'fa fa-genderless kt-font-danger',
          text: 'Received a new feedback on <a href="#" class="kt-link kt-link--brand kt-font-bolder">FinancePro App</a> product.'
        },
        {
          time: '15:30',
          icon: 'fa fa-genderless kt-font-danger',
          text: 'New notification message has been received on <a href="#" class="kt-link kt-link--brand kt-font-bolder">LoopFin Pro</a> product.'
        }
      ]
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    editedItem: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.editedItem = newVal;
      }
    }
  },
  computed: {},
  created() {
    this.fetchData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Tickets' }]);
  },
  methods: {
    handleManualReward() {
      ApiService.setHeader();
      ApiService.patch(`/tickets/${this.editedItem.id}/reward`)
        .then(() => {
          this.$bvToast.toast('Trả thưởng thành công.', {
            title: 'Thông báo trả thưởng',
            variant: 'success',
            autoHideDelay: 3000,
            noCloseButton: true
          });
          this.filter_reward_status = 'Paid';
          this.fetchData();
        })
        .catch((errors) => {
          this.$bvToast.toast(`${errors}`, {
            variant: 'danger',
            autoHideDelay: 3000,
            noCloseButton: true
          });
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    closeUploadDialog() {
      this.dialogUploadTransfer = false;
      this.nameValidate = null;
      this.idValidate = null;
      this.phoneValidate = null;
      this.invalidPhoneMessage = '';
      this.invalidNameMessage = '';
      this.invalidPosMessage = '';
    },
    uploadTransferInfo(bvModalEvt) {
      // Cancel auto close
      bvModalEvt.preventDefault();
      // Validate required fields
      this.$validator.validateAll().then(() => {
        if (this.$validator.errors.any()) {
          return;
        }
        let formData = new FormData();
        formData.append('customer_name', this.uploadCustomerName);
        formData.append('customer_id', this.uploadCustomerID);
        formData.append('customer_phone', this.uploadCustomerPhone);
        formData.append('image_file', this.uploadImage);
        formData.append('ticket_id', this.editedItem.id);
        axios({
          method: 'POST',
          url: `tickets/${this.$store.getters.currentUser.data.account_id}/upload-transfer-info`,
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' }
        })
          .then((response) => {
            if (response.data.status) {
              this.close();
              this.$bvToast.toast('Thành công', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
            }
          })
          .catch((errors) => {
            this.$bvToast.toast(`${errors}`, {
              title: null,
              variant: 'danger',
              autoHideDelay: 3000,
              noCloseButton: true
            });
          })
          .finally(() => {
            this.closeUploadDialog();
            this.dialog = false;
          });
      });
    },
    onFilterName: _.debounce(function () {
      this.fetchData();
    }, 500),
    openGallery(id) {
      this.$refs[`lightbox_${id}`].showImage(0);
    },
    async requestPayoutTicket() {
      const res = await Swal.fire({
        title: 'Confirm',
        text: `Are you sure payout for ticket ${this.editedItem.id}. `,
        showCloseButton: true,
        showCancelButton: true
      });
      if (res.isConfirmed) {
        const res = await ApiService.patch(`tickets/${this.editedItem.id}/request-reward`)
          .then((res) => res)
          .catch((e) => {
            return e.response;
          });
        const { data, status } = res;
        if (status == 204) {
          this.close();
          this.$bvToast.toast('Thành công', {
            title: null,
            variant: 'success',
            autoHideDelay: 3000,
            noCloseButton: true
          });
        } else {
          this.$bvToast.toast(`${data}`, {
            title: null,
            variant: 'danger',
            autoHideDelay: 3000,
            noCloseButton: true
          });
        }
      }
    },
    fetchData() {
      if (this.filter_game !== null) {
        this.isFilterByGame = false;
      } else {
        this.isFilterByGame = true;
      }
      this.isBusy = true;
      let limit = this.perPage;
      let offset = this.currentPage;
      ApiService.setHeader();
      const rewardStatus = this.rewardStatusFilterStatus[this.filter_reward_status];
      const adsTicket = ApiService.get(
        'big-prize-tickets',
        `?game=${this.filter_game || ''}&period=${this.filter_period || ''}&from=${this.filter_from || ''}&to=${
          this.filter_to || ''
        }&limit=${limit}&offset=${offset}&rewardStatus=${rewardStatus == undefined ? '' : rewardStatus}`
      );
      const allGame = ApiService.get('allgame');
      axios
        .all([adsTicket, allGame])
        .then(
          axios.spread((...response) => {
            const ticketRes = response[0];
            const allGameRes = response[1];
            this.items = ticketRes.data.data.ticketList.map((ads) => {
              return {
                ...ads,
                period: {
                  lottery_time: formatDateForView(ads.period.lottery_time),
                  name: ads.period.name
                },
                created_at: formatDateForView(ads.created_at),
                dispatched_at: formatDateForView(ads.dispatched_at),
                printed_at: formatDateForView(ads.printed_at),
                image_preview: [{ thumb: ads.image_url, src: ads.image_url }],
                origin_image_preview: [{ thumb: ads.origin_url, src: ads.origin_url }]
              };
            });
            this.rows = ticketRes.data.data.total;
            this.allGameAdsList = allGameRes.data.data;
            this.timeline = ticketRes.data.data.timeline || [];
            this.isBusy = false;
          })
        )
        .catch((errors) => {
          this.$bvToast.toast(`${errors}`, {
            title: null,
            variant: 'danger',
            autoHideDelay: 3000,
            noCloseButton: true
          });
          this.isBusy = false;
        });
    },
    viewItem: function (item) {
      this.dialog = true;
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = {
        ...item
      };
      this.isLoading = true;
      ApiService.get('tickets', `${this.editedItem.id}`)
        .then((resp) => {
          if (resp.status === 200) {
            this.viewItems = resp.data.data;
            this.modalTitle = `Ticket ${this.viewItems.order.display_order_id}`;
            this.isLoading = false;
          }
        })
        .catch((err) => {
          this.$bvToast.toast(`${err}`, {
            title: null,
            variant: 'danger',
            autoHideDelay: 3000,
            noCloseButton: true
          });
          this.isBusy = false;
        });
    },
    close() {
      this.dialog = false;
      // this.editedItem = Object.assign({}, this.defaultItem);
      this.editedItem = {
        id: '',
        barcode: '',
        created_at: '',
        dispatched_at: '',
        display_ticket_id: '',
        full_serial: '',
        status: '',
        image_url: '',
        last_four_serial: '',
        notice: '',
        printed_at: '',
        lottery_time: '',
        name: '',
        period_id: '',
        ticket_name: '',
        period_name: '',
        price: '',
        game: {
          name: ''
        },
        product: {
          name: ''
        }
      };
      this.editedIndex = -1;
      this.selectedGame = null;
      this.filter_status = null;
      // this.viewItems = [];
    }
  }
};
</script>
